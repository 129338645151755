<template>
    <settings-wrapper>
        <template v-slot:child>
            <!-- <div class="w-80 center pv3"> <a href='settings-teams.html' >Team </a></div> -->
            <div class="w-90 center">
                <teamsHeader />
            </div>
            <div class="w-90 center flex justify-end">
                <button v-on:click="showCreateRole()" class="flex gap8"><span>+</span><span>Create Custom Role</span></button>
            </div>
            <div class="w-90 center mt2">
                <div class="flex flex-wrap">
                    <div class="w-20-l w-100 tc">
                        <div class="b pv3" style="background: #d0d9ff; border-radius: 4px 0 0 0">Default Roles</div>
                        <div :class="state.showBusinessOwnerInfo ? 'pa3 active-team' : ' pa3'">
                            <a v-on:click="showBusinessOwnerInfo" class="pointer">Business Owner</a>
                        </div>
                        <!-- <div class="pa2 box-border-bottom"><a href="POS-Operation.html">Branch Manager</a></div> -->
                        <!-- <div :class=" state.showSalesTellerInfo ? 'pa2 box-border-bottom active-team' :'pa2 box-border-bottom'">
                            <a v-on:click="showSalesTellerInfo" class="pointer">Sales Teller</a></div> -->
                        <!-- <div class="pa2 box-border-bottom"><a href="">Expense Manager</a></div>
                        <div class="pa2 box-border-bottom"><a href="">Inventory Manager</a></div> -->
                        <div
                            v-for="role of state.defaultRoles"
                            v-bind:key="role._id"
                            :class="state.selectedCustomRole === role.roleName ? 'pa3 active-team' : 'pa3'"
                        >
                            <a v-on:click="selectCustomRole(role)" class="pointer">{{ role.roleName }}</a>
                        </div>
                        <div class="b pv3">Custom Roles</div>
                        <div class="lh-copy pa2">
                            You can create your own custom roles, and choose exactly what team members can see and do.
                        </div>
                        <div class="font-w1 pt3 pb5" style="color: #132c8c; cursor: pointer">
                            <div
                                v-for="role of state.customRoles"
                                v-bind:key="role._id"
                                :class="state.selectedCustomRole === role.roleName ? 'pa2 active-team' : 'pa2'"
                            >
                                <a v-on:click="selectCustomRole(role)" class="pointer">{{ role.roleName }}</a>
                            </div>
                            <!-- <a v-on:click="showCustomRoleModal">
                                + Create a custom role
                            </a> -->
                        </div>
                    </div>
                    <div class="w-80-l w-100">
                        <div v-if="state.showBusinessOwnerInfo">
                            <div class="pa3">
                                <div class="b pb3">Business Owner</div>
                                <div class="pb3">
                                    This role grants users the permissions to manage everything on the dashboard
                                </div>
                            </div>
                            <div class="pa3">
                                <div class="flex items-center pb3">
                                    <img src="imgs/people.svg" alt="" />
                                    <div class="pl2" style="color: #132c8c">
                                        Team Members with this role {{ state.businessOwner.length }}:
                                        {{ state.businessOwnerNames }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="!hideBusinessOwnerAccess" class="flex justify-between flex-wrap pa3">
                                <div class="team-management box-border">
                                    <div class="pa3" style="background: #e0f0d9">
                                        <div style="color: #4a8033">What this role can access</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can View Transactions</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can View Customer</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can Create New Customer</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can Manage & Update Customer</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can Create New Customer</div>
                                    </div>
                                </div>
                                <div class="team-management box-border mt4 mt0-l">
                                    <div class="pa3" style="background: #f2dede">
                                        <div style="color: #b84c4c">What this role cannot access</div>
                                    </div>
                                    <div class="tc pt5 ph4">
                                        <div class="f4 pb3">This role has full access!</div>
                                        <div class="lh-copy">
                                            Any team member with this role can access all the sections of the dashboard.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="state.showSalesTellerInfo">
                            <div class="pa3">
                                <div class="b pb3">Sales Teller</div>
                                <div class="box-border-bottom pb3">
                                    This role grants users the permissions to manage everything on the dashboard
                                </div>
                            </div>
                            <div class="pa3">
                                <div class="flex items-center pb3">
                                    <img src="imgs/people.svg" alt="" />
                                    <div class="pl2" style="color: #132c8c">
                                        Team Members with this role {{ state.salesTellers.length }}: {{ state.salesTellerNames }}
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-between flex-wrap pa3">
                                <div class="team-management box-border">
                                    <div class="pa3" style="background: #e0f0d9">
                                        <div style="color: #4a8033">What this role can access</div>
                                    </div>
                                    <div class="pa3">
                                        <div>Can View Transactions</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can View Customer</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can Create New Customer</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can Manage & Update Customer</div>
                                    </div>
                                    <div class="pa3 box-border-bottom">
                                        <div>Can Create New Customer</div>
                                    </div>
                                </div>
                                <div class="team-management box-border mt4 mt0-l">
                                    <div class="pa3" style="background: #f2dede">
                                        <div style="color: #b84c4c">What this role cannot access</div>
                                    </div>
                                    <div class="tc pt5 ph4">
                                        <div class="f4 pb3">This role has full access!</div>
                                        <div class="lh-copy">
                                            Any team member with this role can access all the sections of the dashboard.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <div class="pa3">
                                <div class="b pb3 flex justify between editIcon">
                                    <div class="w-50">
                                        <span> {{ state.customRoleName }}</span>
                                    </div>
                                    <div class="w-50 tr">
                                        <img
                                            v-if="!state.showDefaultRole"
                                            @click="editCustomRoles()"
                                            aria-disabled="true"
                                            src="../../assets/images/settings-blue.svg"
                                            class="pl2"
                                            alt=""
                                        />
                                        <span
                                            v-else-if="state.showDefaultRole && role === 'owner'"
                                            @click="editCustomRoles()"
                                            aria-disabled="true"
                                            class="pl2"
                                            style="color: #132c8c; cursor: pointer"
                                            alt=""
                                            >Edit</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="pa3">
                                <div class="flex items-center pb3">
                                    <img src="imgs/people.svg" alt="" />
                                    <div class="pl2" style="color: #132c8c">
                                        Team Members with this role {{ state.customeRoleMembers.length }}:
                                        {{ state.customRolesNames }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="!hideOtherRolesAccess" class="flex justify-between flex-wrap pa3">
                                <div class="team-management box-border">
                                    <div class="pa3" style="background: #e0f0d9">
                                        <div style="color: #4a8033">What this role can access</div>
                                    </div>
                                    <div v-for="permissions of ALL_PERMISSIONS" v-bind:key="permissions.value">
                                        <div
                                            v-if="state.customRolePermissions?.includes(permissions.value)"
                                            class="pa3 box-border-bottom"
                                        >
                                            <div>{{ permissions.info }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="team-management box-border mt4 mt0-l">
                                    <div class="pa3" style="background: #f2dede">
                                        <div style="color: #b84c4c">What this role cannot access</div>
                                    </div>
                                    <div v-for="permissions of NOT_PERMITTED" v-bind:key="permissions.value">
                                        <div
                                            v-if="!state.customRolePermissions?.includes(permissions.value)"
                                            class="pa3 box-border-bottom"
                                        >
                                            <div>{{ permissions.info }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="w-100 center pt4" id="Modal-create-custom-role"> 
                    <div class="w-50-l w-90 center box-border" style="background: white">
                        <div class="flex justify-between pa3 box-border-bottom">
                            <div class="f3 b">{{ state.isEdit ? 'Edit Custom Role' : 'Create Custom Role' }}</div>
                            <div>
                                <img :src="require('@/assets/images/Close-icon.svg')" alt=" " />
                            </div>
                        </div>
                        <div class="pa3 box-border-bottom">
                            <div class="flex flex-column pb3">
                                <label class="pb2 font-w1" for="">Name Role</label>
                                <input type="text" placeholder="Enter a name" class="box-border pa2" />
                            </div>
                            <div class="flex flex-column pb3">
                                <label class="pb2 font-w1" for="">Role Description</label>
                                <input type="text" placeholder="Enter a description" class="box-border pa2" />
                            </div>
                        </div>
                        <div class="b pa3">Dashboard</div>
                        <div class="pa3">
                            <div class="flex justify-between pb3 box-border-bottom">
                                <div class="flex items-center">
                                    <img src="imgs/check_box.svg" alt="" />
                                    <div class="pl2">Graphs</div>
                                </div>
                                <div class="flex items-center">
                                    <div>Can edit</div>
                                    <img src="imgs/down-o.svg" class="pl2" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between pb3 box-border-bottom ph3 pv2">
                            <div class="flex items-center">
                                <img src="imgs/check_box.svg" alt="" />
                                <div class="pl2">Transactions</div>
                            </div>
                            <div class="flex items-center">
                                <div>Cannot</div>
                                <img src="imgs/down-o.svg" class="pl2" alt="" />
                            </div>
                        </div>
                        <div class="b pa3">Transactions</div>
                        <div class="pa3">
                            <div class="flex justify-between pb3 box-border-bottom">
                                <div class="flex items-center">
                                    <img src="imgs/check_box.svg" alt="" />
                                    <div class="pl2">Create new transactions</div>
                                </div>
                                <div class="flex items-center">
                                    <div>Cannot</div>
                                    <img src="imgs/down-o.svg" class="pl2" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="pa3">
                            <div class="flex justify-between pb3 box-border-bottom">
                                <div class="flex items-center">
                                    <img src="imgs/check_box.svg" alt="" />
                                    <div class="pl2">Customers transactions</div>
                                </div>
                                <div class="flex items-center">
                                    <div>Can view</div>
                                    <img src="imgs/down-o.svg" class="pl2" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="pa3">
                            <div class="flex justify-between pb3 box-border-bottom">
                                <div class="flex items-center">
                                    <img src="imgs/check_box.svg" alt="" />
                                    <div class="pl2">Delete transactions</div>
                                </div>
                                <div class="flex items-center">
                                    <div>Cannot</div>
                                    <img src="imgs/down-o.svg" class="pl2" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between ph3 pb3">
                            <div></div>
                            <div>
                                <button class="btn2">Cancel</button>
                                <button class="ml2">Invite member</button>
                            </div>
                        </div>
                    </div>
                </div>-->
                        <!-- </div> -->
                        <!-- modal invite team -->
                        <!-- <div
                :class="state.showCreateCustomRole && 'jquery-modal blocker current'"
                @click="onClickOutsideInviteRef"
                v-if="state.showCreateCustomRole"
            >
                <div
                    ref="createRoleRef"
                    :class="!state.showCreateCustomRole ? 'modal2' : 'modal-target2 w-50-l w-80 h-50 center box-border'"
                    style="background: white; overflow-y: scroll"
                >
                    <div class="flex justify-between pa3 box-border-bottom">
                        <div class="f3 b">{{ state.isEdit ? 'Edit Custom Role' : 'Create Custom Role' }}</div>
                        <div>
                            <img :src="require('@/assets/images/Close-icon.svg')" alt=" " />
                        </div>
                    </div>
                    <div class="pa3 box-border-bottom">
                        <div class="flex flex-column pb3">
                            <label class="pb2 font-w1" for="">Name Role <span style="color: red">*</span></label>
                            <input v-model="state.roleName" type="text" placeholder="Enter a name" class="box-border pa2" />
                            <div style="color: red" v-if="roleValidation">Role name is required</div>
                        </div>
                        <div class="flex flex-column pb3">
                            <label class="pb2 font-w1" for="">Role Description</label>
                            <input
                                type="text"
                                placeholder="Enter a description"
                                class="box-border pa2"
                                v-model="state.roleDescription"
                            />
                        </div>
                    </div>
                    <div class="flex pa3">
                        <span class="pl4 b center underlineText">View access </span>
                    </div>
                    <div class="graphHeader">
                        <div class="flex w-100 justify-between revenue-option-d">
                            <div class="dropdown">
                                <a class="flex pa1">
                                    <input type="checkbox" id="viewAccess" :value="100" v-model="state.viewAccess" />
                                    <span class="pl2 thefont-bold">All Business records/Only records created</span>
                                </a>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        id="dashboard"
                                        name="viewAccess"
                                        :value="150"
                                        v-model="state.viewAccess"
                                    />
                                    <label class="pl2 thefont-bold" for="dashboard">Dashboard</label>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="51"
                                        v-model="state.viewAccess"
                                        @change="roleToggle(51)"
                                    />
                                    <div class="pl2 thefont-bold">Transactions</div>
                                </div>
                                <div class="thegrid thegrid-cols-4 mydropdown">
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="1" v-model="state.viewAccess" />
                                        <span class="pl2">All</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="2" v-model="state.viewAccess" />
                                        <span class="pl2">Sales</span>
                                    </div>

                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="4" v-model="state.viewAccess" />
                                        <span class="pl2">Expense</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="3" v-model="state.viewAccess" />
                                        <span class="pl2">Purchases</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="42" v-model="state.viewAccess" />
                                        <span class="pl2">Others</span>
                                    </div>
                                </div>

                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="50"
                                        v-model="state.viewAccess"
                                        @change="roleToggle(50)"
                                    />
                                    <div class="pl2 thefont-bold">Inventory</div>
                                </div>
                                <div class="flex flex-wrap justify-content-start items-center w-100">
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="5" v-model="state.viewAccess" />
                                        <span class="pl2">Summary</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="6" v-model="state.viewAccess" />
                                        <span class="pl2">All Products</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="7" v-model="state.viewAccess" />
                                        <span class="pl2">Analysis</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="8" v-model="state.viewAccess" />
                                        <span class="pl2">Product details</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="56" v-model="state.viewAccess" />
                                        <span class="pl2">Purchase order</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="61" v-model="state.viewAccess" />
                                        <span class="pl2">Transfer</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="68" v-model="state.viewAccess" />
                                        <span class="pl2">Category</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="72" v-model="state.viewAccess" />
                                        <span class="pl2">Brand</span>
                                    </div>
                                </div>

                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="9"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Services</span>
                                </div>

                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="12"
                                        v-model="state.viewAccess"
                                        @change="roleToggle(12)"
                                    />
                                    <span class="pl2 thefont-bold">Debtor & Creditors</span>
                                </div>
                                <div class="thegrid thegrid-cols-4 mydropdown">
                                    <a class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="11" v-model="state.viewAccess" />
                                        <span class="pl2">Creditor</span>
                                    </a>
                                    <a class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="10" v-model="state.viewAccess" />
                                        <span class="pl2">Debtor</span>
                                    </a>
                                </div>

                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="13"
                                        v-model="state.viewAccess"
                                        @change="roleToggle(13)"
                                    />
                                    <span class="pl2 thefont-bold">Entity</span>
                                </div>

                                <div class="flex flex-wrap justify-content-start items-center w-100">
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="14" v-model="state.viewAccess" />
                                        <span class="pl2">Customer details</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="66" v-model="state.viewAccess" />
                                        <span class="pl2">Import entity</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="67" v-model="state.viewAccess" />
                                        <span class="pl2">Export entity</span>
                                    </div>
                                </div>

                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="141"
                                        v-model="state.viewAccess"
                                        ref="isChecked"
                                    />
                                    <span class="pl2 thefont-bold">POS</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="142"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Production</span>
                                </div>

                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="15"
                                        v-model="state.viewAccess"
                                        @change="roleToggle(15)"
                                    />
                                    <span class="pl2 thefont-bold">Reports</span>
                                </div>
                                <div class="flex flex-wrap justify-content-start items-center w-100">
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="16" v-model="state.viewAccess" />
                                        <span class="pl2">Sales</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="17" v-model="state.viewAccess" />
                                        <span class="pl2">Expense</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="18" v-model="state.viewAccess" />
                                        <span class="pl2">Inventory</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="19" v-model="state.viewAccess" />
                                        <span class="pl2">P&L</span>
                                    </div>

                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="20" v-model="state.viewAccess" />
                                        <span class="pl2 moreWords">Balance sheet</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="21" v-model="state.viewAccess" />
                                        <span class="pl2">Invoices</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex pa3">
                        <span class="pl4 b center underlineText">Create Access </span>
                    </div>
                    <div class="graphHeader">
                        <div class="flex w-100 justify-between revenue-option-d">
                            <div class="dropdown">
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="22"
                                        v-model="state.viewAccess"
                                        @change="roleToggle(22)"
                                    />
                                    <span class="pl2 thefont-bold">Transactions</span>
                                </div>
                                <div class="flex flex-wrap justify-content-start items-center w-100">
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="23" v-model="state.viewAccess" />
                                        <span class="pl2">Sales</span>
                                    </div>

                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="24" v-model="state.viewAccess" />
                                        <span class="pl2">Expense</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="25" v-model="state.viewAccess" />
                                        <span class="pl2">Purchases</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="26" v-model="state.viewAccess" />
                                        <span class="pl2 moreWords">Money In</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="27" v-model="state.viewAccess" />
                                        <span class="pl2 moreWords">Money Out</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="28" v-model="state.viewAccess" />
                                        <span class="pl2 moreWords">Credit Sale</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="29" v-model="state.viewAccess" />
                                        <span class="pl2 moreWords">Credit Buy</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="52" v-model="state.viewAccess" />
                                        <span class="pl2 moreWords">Import</span>
                                    </div>
                                    <div class="flex pa2 pl4">
                                        <input type="checkbox" class="viewAccess" :value="53" v-model="state.viewAccess" />
                                        <span class="pl2 moreWords">Export</span>
                                    </div>
                                </div>

                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="30"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Customer</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="31"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Invoices</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="32"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Product</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="33"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Service</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="34"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Products</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="54"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Bulk product update</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="55"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Export product</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="57"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Purchase order</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input type="checkbox" class="viewAccess" :value="60" v-model="state.viewAccess" />
                                    <span class="pl2 thefont-bold">Approve purchase order</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="62"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Transfer</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input type="checkbox" class="viewAccess" :value="65" v-model="state.viewAccess" />
                                    <span class="pl2 thefont-bold">Approve transfer</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="69"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Category</span>
                                </div>
                                <div class="flex pa1 mt2">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="73"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2 thefont-bold">Brand</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex pa3">
                        <span class="pl4 b center underlineText">Edit Access </span>
                    </div>
                    <div class="graphHeader">
                        <div class="flex makeWidth100 justify-between revenue-option-d">
                            <div class="flex flex-wrap justify-content-start items-center w-100">
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="35"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Entity</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="36"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Product</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="37"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Prices (Buy and Sell)</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="58"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Purchase order</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="63"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Transfer</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="70"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Category</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        class="viewAccess"
                                        name="viewAccess"
                                        :value="74"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Brand</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex pa3">
                        <span class="pl4 b center underlineText">Delete Access </span>
                    </div>
                    <div class="graphHeader">
                        <div class="flex makeWidth100 revenue-option-d">
                            <div class="flex flex-wrap justify-content-start items-center w-100">
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="38"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Transaction</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="39"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Entity</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="40"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Product</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="41"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Invoice</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="59"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Purchase order</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="64"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Transfer</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="71"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Category</span>
                                </div>
                                <div class="flex pa1">
                                    <input
                                        type="checkbox"
                                        id="viewAccess"
                                        name="viewAccess"
                                        :value="75"
                                        v-model="state.viewAccess"
                                    />
                                    <span class="pl2">Brand</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between ph3 pb3 mt3">
                        <div></div>
                        <div>
                            <button class="btn2" @click="showCustomRoleModal()">Cancel</button>
                            <button v-on:click="state.isEdit ? editRole() : createNewRole()" class="ml2">
                                {{ state.isEdit ? 'Submit' : 'Create Custom Role' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->
                        <div v-if="showCreateCustomAccess" class="w-100-l w-100">
                            <div class="flex">
                                <div class="w-100 pa2">
                                    <div class="pa3">
                                        <h2 class="mb-0">{{ state.isEdit ? 'Edit Custom Role' : 'Create Custom Role' }}</h2>
                                        <p>This role grants users the permissions to manage everything on the dashboard</p>
                                    </div>

                                    <div class="pa3">
                                        <div class="flex flex-column pb3">
                                            <label class="pb2 font-w1" for="">Name Role <span style="color: red">*</span></label>
                                            <input
                                                v-model="state.roleName"
                                                type="text"
                                                placeholder="Enter a name"
                                                class="box-border pa2 pv3"
                                            />
                                            <div style="color: red" v-if="roleValidation">Role name is required</div>
                                        </div>
                                        <div class="flex flex-column pb3">
                                            <label class="pb2 font-w1" for="">Role Description</label>
                                            <input
                                                type="text"
                                                placeholder="Enter a description"
                                                class="box-border pa2 pv3"
                                                v-model="state.roleDescription"
                                            />
                                        </div>
                                        <div>
                                            <div
                                                class="pa3"
                                                style="background: #dfffec; color: #32704c; border-radius: 4px 2px 0 0"
                                            >
                                                <p class="flex gap4 items-center">
                                                    <span>Role</span>
                                                    <strong
                                                        ><font-awesome-icon
                                                            icon="fa-solid fa-question-circle fa-2xs"
                                                            class="clickModalIcon"
                                                            @click="openRoleModal = true"
                                                            style="cursor: pointer; padding-bottom: 2px"
                                                    /></strong>
                                                </p>
                                                <div class="dashboard-inquiry-modal" style="width: 25%" v-if="openRoleModal">
                                                    <p>Control what users can do with clear access levels:</p>
                                                    <ul>
                                                        <li>
                                                            <strong>View:</strong> See information for products, dashboards,
                                                            transactions, etc.
                                                        </li>
                                                        <li>
                                                            <strong>Create:</strong> Add new things like products and invoices.
                                                        </li>
                                                        <li>
                                                            <strong>Edit:</strong> Modify existing things (edit products, update
                                                            invoices).
                                                        </li>
                                                        <li>
                                                            <strong> Delete:</strong> Permanently remove things (delete invoices,
                                                            transactions).
                                                        </li>
                                                    </ul>
                                                    <p>
                                                        Each user role gets specific access for each area, keeping your data
                                                        secure.
                                                    </p>
                                                </div>
                                            </div>
                                            <div style="background-color: #f2f6fc">
                                                <div>
                                                    <div class="flex justify-between pb3 pa3">
                                                        <div>Transactions</div>
                                                        <div>
                                                            <Toggle v-model="toggleValue1" class="togglePlan" id="toggle" />
                                                        </div>
                                                    </div>
                                                    <div v-show="toggleValue1">
                                                        <div class="flex justify-between w-100 pa3">
                                                            <div class="flex gap4 w-30">
                                                                <span>Select All</span
                                                                ><span
                                                                    ><input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="120"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(120)"
                                                                        style="margin-top: 1px"
                                                                /></span>
                                                            </div>
                                                            <div class="w-25 tc">View Access</div>
                                                            <div class="w-25 tc">Create Access</div>
                                                            <div class="w-25 tc">Edit Access</div>
                                                            <div class="w-25 tc">Delete Access</div>
                                                        </div>
                                                        <div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="120"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(120)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Transactions</span>
                                                                        <strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalTxn = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalTxn"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong> See information for
                                                                                products, dashboards, transactions, etc.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Add new things like
                                                                                products and invoices.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong> Modify existing things
                                                                                (edit products, update invoices).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Permanently remove
                                                                                things (delete invoices, transactions).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="1"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(51)"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="22"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(22)"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="116"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="118"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="130"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(130)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Sales</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalSales = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalSales"
                                                                    >
                                                                        <ul>
                                                                            <li><strong>View:</strong>See a list of sales.</li>
                                                                            <li><strong>Create:</strong> Create new sales.</li>
                                                                            <li>Modify existing sale (quantities, prices).</li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Permanently remove
                                                                                Sales.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="2"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="23"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="116"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="118"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="290"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(290)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Expenses</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalExp = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalExp"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong> See information for
                                                                                products, dashboards, transactions, etc.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Add new things like
                                                                                products and invoices.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong> Modify existing things
                                                                                (edit products, update invoices).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Permanently remove
                                                                                things (delete invoices, transactions).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="4"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="24"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="116"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="118"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="300"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(300)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Purchases</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalPur = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalPur"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>See a list of purchase
                                                                                orders.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Create new purchase
                                                                                orders.
                                                                            </li>
                                                                            <li>
                                                                                Modify existing purchase orders (quantities,
                                                                                prices).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Permanently remove
                                                                                Purchase transactions.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="3"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="25"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="116"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="118"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="310"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(310)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Money In</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalMoneyIn = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalMoneyIn"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong> See a breakdown of incoming
                                                                                and outgoing funds.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Create new Money In or
                                                                                Money Out Transactions
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong> May allow editing
                                                                                descriptions for categorized transactions (Money
                                                                                In/Out).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Permanently remove
                                                                                money-in or money-out transactions.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="42"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="27"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="116"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="118"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="320"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(320)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Money Out</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalMoneyOut = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalMoneyOut"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong> See a breakdown of incoming
                                                                                and outgoing funds.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Create new Money In or
                                                                                Money Out Transactions
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong> May allow editing
                                                                                descriptions for categorized transactions (Money
                                                                                In/Out).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Permanently remove
                                                                                money-in or money-out transactions.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="42"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="26"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="116"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="118"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="330"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(330)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex">
                                                                        <span class="font12">Invoices & Quotations</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalInv = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalInv"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>See existing invoices and
                                                                                quotations.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Create new invoices and
                                                                                quotations.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Modify drafts or sent
                                                                                invoices/quotations (quantities, prices).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Delete draft
                                                                                invoices/quotations. You cannot delete sent
                                                                                invoices due to accounting regulations.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="21"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="31"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="118"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class="flex justify-between pb3 pa3">
                                                        <div>Inventory</div>
                                                        <div>
                                                            <Toggle v-model="toggleValue2" class="togglePlan" id="toggle" />
                                                        </div>
                                                    </div>
                                                    <div v-show="toggleValue2">
                                                        <div class="flex justify-between w-100 pa3">
                                                            <div class="flex gap4 w-30">
                                                                <span>Select All</span
                                                                ><span
                                                                    ><input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="410"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(410)"
                                                                        style="margin-top: 1px"
                                                                /></span>
                                                            </div>
                                                            <div class="w-25 tc">View Access</div>
                                                            <div class="w-25 tc">Create Access</div>
                                                            <div class="w-25 tc">Edit Access</div>
                                                            <div class="w-25 tc">Delete Access</div>
                                                        </div>
                                                        <div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="5"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Summary</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalSummary = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalSummary"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>See overall inventory
                                                                                levels, value, and reports.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong>Not applicable for Summary
                                                                                (generated from other areas).
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Not applicable for Summary
                                                                                (data comes from other areas).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Not applicable for
                                                                                Summary.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="5"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="340"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(340)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Services</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalServices = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalServices"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>SSee a list of services
                                                                                offered.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Add new services to your
                                                                                inventory.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Modify existing services
                                                                                (descriptions, pricing).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Mark services as
                                                                                inactive (removes from active listings).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="9"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="33"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="36"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="40"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="350"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(350)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Products</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalProducts = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalProducts"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>See a list of products with
                                                                                details (stock levels, descriptions).
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Add new products to your
                                                                                inventory.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Modify existing products
                                                                                (descriptions, pricing, stock levels).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong>Mark products as inactive
                                                                                (removes from active listings).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="6"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="32"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="36"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="40"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="360"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(360)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Purchase Order</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalPurchaseOrder = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="dashboard-inquiry-modal"
                                                                    style="width: 20%; padding: 10px !important"
                                                                    v-if="openRoleModalPurchaseOrder"
                                                                >
                                                                    <ul>
                                                                        <li>
                                                                            <strong>View:</strong>SSee a list of existing purchase
                                                                            orders.
                                                                        </li>
                                                                        <li>
                                                                            <strong>Create:</strong>Create new purchase orders for
                                                                            acquiring inventory.
                                                                        </li>
                                                                        <li>
                                                                            <strong>Edit:</strong>Modify existing purchase orders
                                                                            (quantities, prices) before finalizing.
                                                                        </li>
                                                                        <li>
                                                                            <strong> Delete:</strong>Delete draft purchase orders
                                                                            (before sending to suppliers).
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="56"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="57"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="58"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="59"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="370"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(370)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Transfer</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalTransfer = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalTransfer"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>See a history of inventory
                                                                                transfers between locations and branches.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Initiate transfers from
                                                                                one branch/location to another
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Potentially used to edit
                                                                                transfer details (quantities, locations) before
                                                                                finalizing.
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong>Delete draft transfers
                                                                                (before sending to other branches/locations).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="61"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="62"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="63"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="64"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="380"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(380)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Category</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalCategory = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalCategory"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>See a list of existing
                                                                                categories and brands used for inventory
                                                                                organization.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Add new categories and
                                                                                brands for organizing your inventory.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Modify existing categories
                                                                                and brands (names, descriptions).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Delete unused categories
                                                                                and brands (doesn't affect assigned products).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="68"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="69"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="70"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="71"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="390"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(390)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Brand</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalBrand = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalBrand"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>See a list of existing
                                                                                categories and brands used for inventory
                                                                                organization.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Add new categories and
                                                                                brands for organizing your inventory.
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Modify existing categories
                                                                                and brands (names, descriptions).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Delete unused categories
                                                                                and brands (doesn't affect assigned products).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="72"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="73"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="74"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="75"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="400"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(400)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Bulk</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalBulk = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalBulk"
                                                                    >
                                                                        <ul>
                                                                            <li>
                                                                                <strong>View:</strong>Export, download and view
                                                                                product listing in csv
                                                                            </li>
                                                                            <li>
                                                                                <strong>Create:</strong> Initiate Bulk Product
                                                                                creation from csv (user initiates bulk actions).
                                                                            </li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Potentially used for bulk
                                                                                editing product details (prices, quantities).
                                                                            </li>
                                                                            <li>
                                                                                <strong> Delete:</strong> Potentially used for
                                                                                bulk deleting products (marked as inactive).
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="55"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="34"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="54"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                            </div>

                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="37"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Prices</span
                                                                        ><strong
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                class="clickModalIcon"
                                                                                @click="openRoleModalPrices = true"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></strong>
                                                                    </div>
                                                                    <div
                                                                        class="dashboard-inquiry-modal"
                                                                        style="width: 20%; padding: 10px !important"
                                                                        v-if="openRoleModalPrices"
                                                                    >
                                                                        <ul>
                                                                            <li><strong>View:</strong>N/A</li>
                                                                            <li><strong>Create:</strong>N/A</li>
                                                                            <li>
                                                                                <strong>Edit:</strong>Modify existing product and
                                                                                service prices
                                                                            </li>
                                                                            <li><strong> Delete:</strong>N/A</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="37"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="60"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Approve Purchase Order</span
                                                                        ><span>
                                                                            <font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content:
                                                                                        'All the records of Money out transactions',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="60"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="65"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Approve Inventory Transfer</span
                                                                        ><span>
                                                                            <font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content:
                                                                                        'All the records of Money out transactions',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="65"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="flex justify-between pb3 pa3">
                                                        <div>Entity</div>
                                                        <div>
                                                            <Toggle v-model="toggleValue3" class="togglePlan" id="toggle" />
                                                        </div>
                                                    </div>
                                                    <div v-show="toggleValue3">
                                                        <div class="flex justify-between w-100 pa3">
                                                            <div class="flex gap4 w-30">
                                                                <span>Select All</span
                                                                ><span
                                                                    ><input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="280"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(280)"
                                                                        style="margin-top: 1px"
                                                                /></span>
                                                            </div>
                                                            <div class="w-25 tc">View Access</div>
                                                            <div class="w-25 tc">Create Access</div>
                                                            <div class="w-25 tc">Edit Access</div>
                                                            <div class="w-25 tc">Delete Access</div>
                                                        </div>
                                                        <div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="13"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">All</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="13"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="240"
                                                                            @change="roleToggle(240)"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Customer Details</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'All the records of Sale',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="14"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="30"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="35"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        :value="39"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="250"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(250)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Creditors</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'All the records of Expenses',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="12"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(12)"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="29"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="260"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(260)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Debtors</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'All the records of Purchases',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="10"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(12)"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="28"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            :value="270"
                                                                            v-model="state.viewAccess"
                                                                            @change="roleToggle(270)"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Bulk</span
                                                                        ><span>
                                                                            <font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content:
                                                                                        'All the records of Others transactions',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="67"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="66"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc"></div>
                                                                <div class="w-25 tc"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="flex justify-between pb3 pa3">
                                                        <div>Production</div>
                                                        <div>
                                                            <Toggle v-model="toggleValue4" class="togglePlan" id="toggle" />
                                                        </div>
                                                    </div>
                                                    <div v-show="toggleValue4">
                                                        <div class="flex justify-between w-100 pa3">
                                                            <div class="flex gap4 w-30">
                                                                <span>Select All</span
                                                                ><span
                                                                    ><input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="142"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                /></span>
                                                            </div>
                                                            <div class="w-25 tc">View Access</div>
                                                            <div class="w-25 tc">Create Access</div>
                                                            <div class="w-25 tc">Edit Access</div>
                                                            <div class="w-25 tc">Delete Access</div>
                                                        </div>
                                                        <div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="142"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Production</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="142"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="142"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="142"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                                <div class="w-25 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="142"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class="flex justify-between pb3 pa3">
                                                        <div>Reports</div>
                                                        <div>
                                                            <Toggle v-model="toggleValue5" class="togglePlan" id="toggle" />
                                                        </div>
                                                    </div>
                                                    <div v-show="toggleValue5">
                                                        <div class="flex justify-between w-100 pa3">
                                                            <div class="flex gap4 w-30">
                                                                <span>Select All</span
                                                                ><span
                                                                    ><input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="230"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(230)"
                                                                        style="margin-top: 1px"
                                                                /></span>
                                                            </div>
                                                            <div class="w-30 tc">View Access</div>
                                                        </div>
                                                        <div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="16"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Sales</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="16"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="17"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Expenses</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="17"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="18"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Inventory</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="18"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="19"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Profit & Loss</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="19"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="20"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Balance Sheet</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="20"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="flex justify-between pb3 pa3">
                                                        <div>Others</div>
                                                        <div>
                                                            <Toggle v-model="toggleValue6" class="togglePlan" id="toggle" />
                                                        </div>
                                                    </div>
                                                    <div v-show="toggleValue6">
                                                        <div class="flex justify-between w-100 pa3">
                                                            <div class="flex gap4 w-30">
                                                                <span>Select All</span
                                                                ><span
                                                                    ><input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        name="viewAccess"
                                                                        :value="210"
                                                                        v-model="state.viewAccess"
                                                                        @change="roleToggle(210)"
                                                                        style="margin-top: 1px"
                                                                /></span>
                                                            </div>
                                                            <div class="w-30 tc">View Access</div>
                                                        </div>
                                                        <div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="100"
                                                                            @change="roleToggle(100)"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">All Business records</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="100"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="99"
                                                                            @change="roleToggle(99)"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Only records created</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="99"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="150"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">Dashboard</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="150"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="flex justify-between pa3" style="background-color: white">
                                                                <div class="flex gap4 w-30">
                                                                    <span
                                                                        ><input
                                                                            type="checkbox"
                                                                            class="viewAccess"
                                                                            name="viewAccess"
                                                                            :value="141"
                                                                            v-model="state.viewAccess"
                                                                            style="margin-top: 1px"
                                                                    /></span>
                                                                    <div class="flex gap4">
                                                                        <span class="font12">POS</span
                                                                        ><span
                                                                            ><font-awesome-icon
                                                                                icon="fa-solid fa-question-circle fa-2xs"
                                                                                v-tippy="{
                                                                                    content: 'Summary of your Products',
                                                                                    placement: 'top',
                                                                                    interactive: true,
                                                                                }"
                                                                                style="cursor: pointer; padding-bottom: 2px"
                                                                        /></span>
                                                                    </div>
                                                                </div>
                                                                <div class="w-30 tc">
                                                                    <input
                                                                        type="checkbox"
                                                                        class="viewAccess"
                                                                        :value="141"
                                                                        v-model="state.viewAccess"
                                                                        style="margin-top: 1px"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-end gap8 pa3">
                                            <button class="btn2" @click="showCustomRoleModal()">Cancel</button>
                                            <button v-on:click="state.isEdit ? editRole() : createNewRole()">
                                                {{ state.isEdit ? 'Submit' : 'Create Custom Role' }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import { ALL_PERMISSIONS, NOT_PERMITTED } from '@/utils/lib'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import TeamsHeader from '@/views/Settings/widgets/TeamsHeader'
import Toggle from '@vueform/toggle'
export default {
    name: 'ManagedRoles',
    components: { SettingsWrapper, TeamsHeader, Toggle },

    setup() {
        const store = useStore()
        const members = computed(() => store.state.Settings.members)
        const pendingInvites = computed(() => store.state.Settings.pendingInvites)
        const emailVerifiedAt = computed(() => store?.state?.Auth?.userData?.emailVerifiedAt)
        const roles = computed(() => store.state.Settings.customRoles)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const createRoleRef = ref(null)
        const deleteModalRef = ref(null)
        const changeRoleModalRef = ref(null)
        const isChecked = ref(false)
        const disabledTransaction = ref(false)
        const disableInventory = ref(false)
        const disableDebtor = ref(false)
        const disableEntity = ref(false)
        const disableReport = ref(false)
        const disabledTransactionAccess = ref(false)
        const disabledTransactionEditAccess = ref(false)
        const disabledTransactionDeleteAccess = ref(false)
        const disabledAllTransactionAccess = ref(false)
        const disabledAllSalesTransactionAccess = ref(false)
        const disabledAllExpensesTransactionAccess = ref(false)
        const disabledAllPurchasesTransactionAccess = ref(false)
        const disabledAllMoneyInTransactionAccess = ref(false)
        const disabledAllMoneyOutTransactionAccess = ref(false)
        const disabledAllInvoiceTransactionAccess = ref(false)
        const roleValidation = ref('')
        const toggleValue1 = ref(false)
        const toggleValue2 = ref(false)
        const toggleValue3 = ref(false)
        const toggleValue4 = ref(false)
        const toggleValue5 = ref(false)
        const toggleValue6 = ref(false)
        const hideBusinessOwnerAccess = ref(false)
        const hideSalesTellerAccess = ref(false)
        const hideOtherRolesAccess = ref(false)
        const showCreateCustomAccess = ref(false)
        const othersSelectAll = ref(false)
        const reportSelectAll = ref(false)
        const entitySelectAll = ref(false)
        const customerDetailsSelectAll = ref(false)
        const debtorSelectAll = ref(false)
        const creditorSelectAll = ref(false)
        const bulkSelectAll = ref(false)
        const inventorySelectAll = ref(false)
        const inventoryAllServicesAccess = ref(false)
        const inventoryAllProductAccess = ref(false)
        const inventoryAllPurchaseOrderAccess = ref(false)
        const inventoryAllTransferAccess = ref(false)
        const inventoryAllCategoryAccess = ref(false)
        const inventoryAllBrandAccess = ref(false)
        const inventoryAllBulkAccess = ref(false)
        const openRoleModal = ref(false)
        const openRoleModalTxn = ref(false)
        const openRoleModalSales = ref(false)
        const openRoleModalExp = ref(false)
        const openRoleModalPur = ref(false)
        const openRoleModalMoneyIn = ref(false)
        const openRoleModalMoneyOut = ref(false)
        const openRoleModalInv = ref(false)

        const openRoleModalSummary = ref(false)
        const openRoleModalServices = ref(false)
        const openRoleModalProducts = ref(false)
        const openRoleModalPurchaseOrder = ref(false)
        const openRoleModalTransfer = ref(false)
        const openRoleModalCategory = ref(false)
        const openRoleModalBrand = ref(false)
        const openRoleModalBulk = ref(false)
        const openRoleModalPrices = ref(false)

        const initialState = () => ({
            disabledButton: false,
            showCreateCustomRole: false,
            viewAccess: [],
            roleDescription: '',
            roleName: '',
            businessOwner: [],
            salesTellers: [],
            salesTellerNames: '',
            businessOwnerNames: '',
            showBusinessOwnerInfo: true,
            showSalesTellerInfo: false,
            showDefaultRole: true,
            selectedCustomRole: '',
            customRoleName: '',
            customRolesNames: '',
            customeRoleMembers: [],
            customRolePermissions: [],
            isEdit: false,
            customRoleId: '',
            showAllBusinessRecords: false,
            customRoles: [],
            defaultRoles: [],
        })
        const state = reactive(initialState())

        const rules = {
            roleName: { required },
            // password: { required }
        }

        const v$ = useVuelidate(rules, state)

        const showBusinessOwnerInfo = () => {
            state.showBusinessOwnerInfo = true
            state.showSalesTellerInfo = false
        }

        const showSalesTellerInfo = () => {
            state.showBusinessOwnerInfo = false
            state.showSalesTellerInfo = true
        }

        const onClickOutsideInviteRef = (e) => {
            if (
                // eslint-disable-next-line
                createRoleRef.value &&
                // eslint-disable-next-line
                !createRoleRef.value.contains(e.target)
            ) {
                state.showCreateCustomRole = false
            }
        }

        const showCustomRoleModal = () => {
            state.isEdit = false
            hideBusinessOwnerAccess.value = false
            hideOtherRolesAccess.value = false
            showCreateCustomAccess.value = false
            state.showCreateCustomRole = !state.showCreateCustomRole
        }

        const editCustomRoles = () => {
            state.isEdit = true
            showCreateCustomAccess.value = true
            hideBusinessOwnerAccess.value = true
            hideOtherRolesAccess.value = true
            state.viewAccess = state.customRolePermissions

            ;(state.roleName = state.selectedCustomRole),
                (state.roleDescription = state.selectedCustomRoleDescription),
                (state.showCreateCustomRole = true)
        }

        const editRole = () => {
            state.viewAccess = state.viewAccess.includes(100)
                ? state.viewAccess.filter((permission) => permission !== 99)
                : [...state.viewAccess, 99]

            const role = {
                roleId: state.customRoleId,
                roleName: state.roleName,
                description: state.roleDescription,
                access: [...new Set(state.viewAccess)],
            }

            store.dispatch('Settings/editRole', role).then(() => {
                store.dispatch('Settings/getCustomRoles')
                store.dispatch('Alert/setAlert', {
                    message: 'Access rights updated. Kindly advise all users to logout and login to effect changes',
                    status: true,
                })
                state.showCreateCustomRole = false
            })
            //console.log(role);
        }

        const createNewRole = () => {
            // v$.value.$touch()
            if (!state.roleName) {
                store.dispatch('Alert/setAlert', { message: 'Role name is required', status: false })

                roleValidation.value = 'Role Name is required'
                return
            }

            state.viewAccess = state.viewAccess.includes(100)
                ? state.viewAccess.filter((permission) => permission !== 99)
                : [...state.viewAccess, 99]
            const role = {
                roleName: state.roleName,
                description: state.roleDescription,
                access: [...new Set(state.viewAccess)],
            }
            store.dispatch('Settings/createRole', role).then((_) => {
                store.dispatch('Settings/getCustomRoles')
                showCustomRoleModal()
            })
            // console.log(state.viewAccess)
        }

        // unless it is incredibly necessary, don't touch anything here
        // this function is being used to get roles belonging to their child elements
        const roleToggle = (num) => {
            if (num === 51) {
                const thenum1 = [1, 2, 4, 3, 42, 21]

                if (disabledTransaction.value === true && !state.viewAccess.includes(thenum1)) {
                    state.viewAccess = state.viewAccess.filter((num) => !thenum1.includes(num))
                    disabledTransaction.value = !disabledTransaction.value
                } else {
                    // if (state.viewAccess.includes(1, 2, 4, 3, 42)) {
                    // const num = [1, 2, 4, 3, 42]
                    // state.viewAccess = state.viewAccess.filter(item => !num.includes(item))
                    // } else {
                    state.viewAccess.push(1, 2, 4, 3, 42, 21)
                    // }
                    disabledTransaction.value = !disabledTransaction.value
                }
                // console.log(disabledTransaction.value, 'disable')
                // console.log(state.viewAccess, 'rest')
            }

            if (num === 50) {
                const thenum2 = [5, 6, 7, 8]
                if (disableInventory.value === true && !state.viewAccess.includes(thenum2)) {
                    state.viewAccess = state.viewAccess.filter((num) => !thenum2.includes(num))
                    disableInventory.value = !disableInventory.value
                } else {
                    state.viewAccess.push(5, 6, 7, 8)
                    disableInventory.value = !disableInventory.value
                }
            }

            if (num == 12) {
                if (disableDebtor.value === true) {
                    const numVal = [10, 11]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disableDebtor.value = !disableDebtor.value
                } else {
                    state.viewAccess.push(10, 11)
                    disableDebtor.value = !disableDebtor.value
                }
            }

            if (num == 13) {
                if (disableEntity.value === true) {
                    const numVal = [14]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disableEntity.value = !disableEntity.value
                } else {
                    state.viewAccess.push(14)
                    disableEntity.value = !disableEntity.value
                }
            }

            if (num == 15) {
                if (disableReport.value === true) {
                    const numVal = [16, 17, 18, 19, 20, 21]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disableReport.value = !disableReport.value
                } else {
                    state.viewAccess.push(16, 17, 18, 19, 20, 21)
                    disableReport.value = !disableReport.value
                }
            }

            if (num == 22) {
                if (disabledTransactionAccess.value === true) {
                    const numVal = [23, 24, 25, 26, 27, 28, 29, 31]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledTransactionAccess.value = !disabledTransactionAccess.value
                } else {
                    state.viewAccess.push(23, 24, 25, 26, 27, 28, 29, 31)
                    disabledTransactionAccess.value = !disabledTransactionAccess.value
                }
            }

            // ** my custom provided numbers are [120, 130, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 330, 340, 350, 360, 370 380, 390, 400, 410]

            if (num == 120) {
                if (disabledAllTransactionAccess.value === true) {
                    const numVal = [
                        130, 290, 300, 310, 320, 330, 51, 22, 115, 117, 118, 116, 23, 24, 25, 26, 27, 28, 29, 1, 2, 4, 3, 42, 21,
                        31, 120,
                    ]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledAllTransactionAccess.value = !disabledAllTransactionAccess.value
                } else {
                    state.viewAccess.push(
                        51,
                        22,
                        21,
                        31,
                        118,
                        116,
                        23,
                        24,
                        25,
                        26,
                        27,
                        28,
                        29,
                        1,
                        2,
                        4,
                        3,
                        42,
                        130,
                        290,
                        300,
                        310,
                        320,
                        330,
                        21,
                        120
                    )
                    disabledAllTransactionAccess.value = !disabledAllTransactionAccess.value
                }
            }
            if (num == 130) {
                if (disabledAllSalesTransactionAccess.value === true) {
                    const numVal = [1, 23, 116, 118, 115, 117]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledAllSalesTransactionAccess.value = !disabledAllSalesTransactionAccess.value
                } else {
                    state.viewAccess.push(1, 23, 116, 118, 115, 117)
                    disabledAllSalesTransactionAccess.value = !disabledAllSalesTransactionAccess.value
                }
            }
            if (num == 290) {
                if (disabledAllExpensesTransactionAccess.value === true) {
                    const numVal = [4, 24, 116, 118, 290]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledAllExpensesTransactionAccess.value = !disabledAllExpensesTransactionAccess.value
                } else {
                    state.viewAccess.push(4, 24, 116, 118)
                    disabledAllExpensesTransactionAccess.value = !disabledAllExpensesTransactionAccess.value
                }
            }
            if (num == 300) {
                if (disabledAllPurchasesTransactionAccess.value === true) {
                    const numVal = [3, 25, 116, 118, 300]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledAllPurchasesTransactionAccess.value = !disabledAllPurchasesTransactionAccess.value
                } else {
                    state.viewAccess.push(3, 25, 116, 118)
                    disabledAllPurchasesTransactionAccess.value = !disabledAllPurchasesTransactionAccess.value
                }
            }
            if (num == 310) {
                if (disabledAllMoneyInTransactionAccess.value === true) {
                    const numVal = [42, 26, 116, 118, 310]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledAllMoneyInTransactionAccess.value = !disabledAllMoneyInTransactionAccess.value
                } else {
                    state.viewAccess.push(42, 26, 116, 118)
                    disabledAllMoneyInTransactionAccess.value = !disabledAllMoneyInTransactionAccess.value
                }
            }
            if (num == 320) {
                if (disabledAllMoneyOutTransactionAccess.value === true) {
                    const numVal = [42, 27, 116, 118, 320]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledAllMoneyOutTransactionAccess.value = !disabledAllMoneyOutTransactionAccess.value
                } else {
                    state.viewAccess.push(42, 27, 116, 118)
                    disabledAllMoneyOutTransactionAccess.value = !disabledAllMoneyOutTransactionAccess.value
                }
            }
            if (num == 330) {
                if (disabledAllInvoiceTransactionAccess.value === true) {
                    const numVal = [21, 31, 118, 330]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    disabledAllInvoiceTransactionAccess.value = !disabledAllInvoiceTransactionAccess.value
                } else {
                    state.viewAccess.push(21, 31, 118)
                    disabledAllInvoiceTransactionAccess.value = !disabledAllInvoiceTransactionAccess.value
                }
            }
            if (num == 210) {
                if (othersSelectAll.value === true) {
                    const numVal = [100, 150, 141]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    othersSelectAll.value = !othersSelectAll.value
                } else {
                    state.viewAccess.push(100, 150, 141)
                    othersSelectAll.value = !othersSelectAll.value
                }
            }
            if (num == 230) {
                if (reportSelectAll.value === true) {
                    const numVal = [20, 19, 18, 17, 16, 15]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    reportSelectAll.value = !reportSelectAll.value
                } else {
                    state.viewAccess.push(20, 19, 18, 17, 16, 15)
                    reportSelectAll.value = !reportSelectAll.value
                }
            }
            if (num == 240) {
                if (customerDetailsSelectAll.value === true) {
                    const numVal = [14, 30, 35, 39, 240]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    customerDetailsSelectAll.value = !customerDetailsSelectAll.value
                } else {
                    state.viewAccess.push(14, 30, 35, 39)
                    customerDetailsSelectAll.value = !customerDetailsSelectAll.value
                }
            }
            if (num == 250) {
                if (creditorSelectAll.value === true) {
                    const numVal = [12, 29, 250]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    creditorSelectAll.value = !creditorSelectAll.value
                } else {
                    state.viewAccess.push(12, 29)
                    creditorSelectAll.value = !creditorSelectAll.value
                }
            }
            if (num == 260) {
                if (debtorSelectAll.value === true) {
                    const numVal = [10, 28, 260]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    debtorSelectAll.value = !debtorSelectAll.value
                } else {
                    state.viewAccess.push(10, 28)
                    debtorSelectAll.value = !debtorSelectAll.value
                }
            }
            if (num == 270) {
                if (bulkSelectAll.value === true) {
                    const numVal = [66, 67, 260]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    bulkSelectAll.value = !bulkSelectAll.value
                } else {
                    state.viewAccess.push(66, 67)
                    bulkSelectAll.value = !bulkSelectAll.value
                }
            }
            if (num == 280) {
                if (entitySelectAll.value === true) {
                    const numVal = [13, 240, 14, 30, 35, 39, 250, 12, 29, 260, 10, 28, 270, 66, 67, 280]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    entitySelectAll.value = !entitySelectAll.value
                } else {
                    state.viewAccess.push(13, 240, 14, 30, 35, 39, 250, 12, 29, 260, 10, 28, 270, 66, 67)
                    entitySelectAll.value = !entitySelectAll.value
                }
            }
            if (num == 340) {
                if (inventoryAllServicesAccess.value === true) {
                    const numVal = [9, 33, 36, 40]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventoryAllServicesAccess.value = !inventoryAllServicesAccess.value
                } else {
                    state.viewAccess.push(9, 33, 36, 40)
                    inventoryAllServicesAccess.value = !inventoryAllServicesAccess.value
                }
            }
            if (num == 350) {
                if (inventoryAllProductAccess.value === true) {
                    const numVal = [6, 32, 36, 40]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventoryAllProductAccess.value = !inventoryAllProductAccess.value
                } else {
                    state.viewAccess.push(6, 32, 36, 40)
                    inventoryAllProductAccess.value = !inventoryAllProductAccess.value
                }
            }
            if (num == 360) {
                if (inventoryAllPurchaseOrderAccess.value === true) {
                    const numVal = [56, 57, 58, 59]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventoryAllPurchaseOrderAccess.value = !inventoryAllPurchaseOrderAccess.value
                } else {
                    state.viewAccess.push(56, 57, 58, 59)
                    inventoryAllPurchaseOrderAccess.value = !inventoryAllPurchaseOrderAccess.value
                }
            }
            if (num == 370) {
                if (inventoryAllTransferAccess.value === true) {
                    const numVal = [61, 62, 63, 64]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventoryAllTransferAccess.value = !inventoryAllTransferAccess.value
                } else {
                    state.viewAccess.push(61, 62, 63, 64)
                    inventoryAllTransferAccess.value = !inventoryAllTransferAccess.value
                }
            }
            if (num == 380) {
                if (inventoryAllCategoryAccess.value === true) {
                    const numVal = [68, 69, 70, 71]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventoryAllCategoryAccess.value = !inventoryAllCategoryAccess.value
                } else {
                    state.viewAccess.push(68, 69, 70, 71)
                    inventoryAllCategoryAccess.value = !inventoryAllCategoryAccess.value
                }
            }
            if (num == 390) {
                if (inventoryAllBrandAccess.value === true) {
                    const numVal = [72, 73, 74, 75]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventoryAllBrandAccess.value = !inventoryAllBrandAccess.value
                } else {
                    state.viewAccess.push(72, 73, 74, 75)
                    inventoryAllBrandAccess.value = !inventoryAllBrandAccess.value
                }
            }

            if (num == 400) {
                if (inventoryAllBulkAccess.value === true) {
                    const numVal = [55, 34, 54]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventoryAllBulkAccess.value = !inventoryAllBulkAccess.value
                } else {
                    state.viewAccess.push(55, 34, 54)
                    inventoryAllBulkAccess.value = !inventoryAllBulkAccess.value
                }
            }
            if (num == 100) {
                state.viewAccess = state.viewAccess.filter((num) => ![99].includes(num))
            }
            if (num == 99) {
                state.viewAccess = state.viewAccess.filter((num) => ![100].includes(num))
            }
            if (num == 410) {
                if (inventorySelectAll.value === true) {
                    const numVal = [
                        5, 340, 9, 33, 36, 40, 350, 6, 32, 36, 40, 360, 56, 57, 58, 59, 370, 61, 62, 63, 64, 380, 68, 69, 70, 71,
                        390, 72, 73, 74, 75, 400, 55, 34, 54, 37, 60, 65,
                    ]
                    state.viewAccess = state.viewAccess.filter((num) => !numVal.includes(num))
                    inventorySelectAll.value = !inventorySelectAll.value
                } else {
                    state.viewAccess.push(
                        5,
                        340,
                        9,
                        33,
                        36,
                        40,
                        350,
                        6,
                        32,
                        36,
                        40,
                        360,
                        56,
                        57,
                        58,
                        59,
                        370,
                        61,
                        62,
                        63,
                        64,
                        380,
                        68,
                        69,
                        70,
                        71,
                        390,
                        72,
                        73,
                        74,
                        75,
                        400,
                        55,
                        34,
                        54,
                        37,
                        60,
                        65
                    )
                    inventorySelectAll.value = !inventorySelectAll.value
                }
            }
            // console.log(state.viewAccess)
        }

        const showCreateRole = () => {
            state.isEdit = false
            hideBusinessOwnerAccess.value = true
            hideOtherRolesAccess.value = true
            showCreateCustomAccess.value = true
            state.viewAccess = []

            state.roleName = ''
            state.roleDescription = ''

            state.showCreateCustomRole = true
        }
        // watchEffect(() => {
        // console.log(state.viewAccess)
        // start of 51
        // if (state.viewAccess.includes(51) && !state.viewAccess.includes(1, 2, 4, 3, 42)) {
        //     state.viewAccess.push(1, 2, 4, 3, 42)
        // }
        // if (!state.viewAccess.includes(51) && state.viewAccess !== null) {
        //     const num = [1, 2, 4, 3, 42]
        //     state.viewAccess = state.viewAccess.filter(item => !num.includes(item))
        // }
        // end of 51
        // start of 50
        // if (state.viewAccess.includes(50)) {
        //     state.viewAccess.push(5, 6, 7, 8)
        // }
        // if (!state.viewAccess.includes(50)) {
        //     const num = [5, 6, 7, 8, 50]
        //     state.viewAccess = state.viewAccess.filter(item => !num.includes(item))
        // }
        // console.log(state.viewAccess)
        // if (JSON.stringify(state.viewAccess.push(51, 3, 2))) {
        //     console.log('it has been checked')
        // }
        // })

        watch(
            () => members.value,
            (next, previous) => {
                state.businessOwner = next.filter((member) => member.role === 'owner')
                state.businessOwnerNames = state.businessOwner.map((member) => member.name).join(' - ')

                state.salesTellers = next.filter((member) => member.role === 'salesTeller')
                state.salesTellerNames = state.salesTellers.map((member) => member.name).join(' - ')
                //console.log(next, 'members');
            }
        )

        watch(
            () => state.showAllBusinessRecords,
            () => {
                //console.log(state.showAllBusinessRecords, 'business records')
            }
        )

        watch(
            () => roles.value,
            (next, prev) => {
                state.customRoles = next.filter((role) => role.updatedBy !== 'simplebks')
                state.defaultRoles = next.filter((role) => role.updatedBy === 'simplebks')

                //console.log(next.length, state.customRoles.length, 'business records')
            }
        )

        const closeModal = () => {
            openRoleModal.value = false
            openRoleModalTxn.value = false
            openRoleModalSales.value = false
            openRoleModalExp.value = false
            openRoleModalPur.value = false
            openRoleModalMoneyIn.value = false
            openRoleModalMoneyOut.value = false
            openRoleModalInv.value = false
            openRoleModalSummary.value = false
            openRoleModalServices.value = false
            openRoleModalProducts.value = false
            openRoleModalPurchaseOrder.value = false
            openRoleModalTransfer.value = false
            openRoleModalCategory.value = false
            openRoleModalBrand.value = false
            openRoleModalBulk.value = false
            openRoleModalPrices.value = false
        }
        onMounted(() => {
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (
                    !(event.target.closest('.clickModalIcon') || event.target === modalIcon) &&
                    (openRoleModal.value === true ||
                        openRoleModalTxn.value === true ||
                        openRoleModalSales.value === true ||
                        openRoleModalExp.value === true ||
                        openRoleModalPur.value === true ||
                        openRoleModalMoneyIn.value === true ||
                        openRoleModalMoneyOut.value === true ||
                        openRoleModalInv.value === true ||
                        openRoleModalSummary.value === true ||
                        openRoleModalServices.value === true ||
                        openRoleModalProducts.value === true ||
                        openRoleModalPurchaseOrder.value === true ||
                        openRoleModalTransfer.value === true ||
                        openRoleModalCategory.value === true ||
                        openRoleModalBrand.value === true ||
                        openRoleModalBulk.value === true ||
                        openRoleModalPrices.value === true)
                ) {
                    closeModal()
                }
            })
        })

        const selectCustomRole = (role) => {
            const { roleName, access, _id, description, updatedBy } = role
            if (updatedBy === 'simplebks') state.showDefaultRole = true
            else state.showDefaultRole = false
            state.selectedCustomRole = roleName
            state.selectedCustomRoleDescription = description
            state.showBusinessOwnerInfo = false
            state.showSalesTellerInfo = false

            state.customRoleName = roleName
            state.customeRoleMembers = members.value.filter((member) => member.role === roleName)
            state.customRolesNames = state.customeRoleMembers.map((member) => member.name).join(' - ')
            state.customRolePermissions = [...new Set(access)]
            state.customRoleId = _id
        }

        onMounted(() => {
            store.dispatch('Settings/getTeamMembers')
            store.dispatch('Settings/getCustomRoles')
        })
        return {
            state,
            openRoleModal,
            openRoleModalTxn,
            openRoleModalSales,
            openRoleModalExp,
            openRoleModalPur,
            openRoleModalMoneyIn,
            openRoleModalMoneyOut,
            openRoleModalInv,
            openRoleModalSummary,
            openRoleModalServices,
            openRoleModalProducts,
            openRoleModalPurchaseOrder,
            openRoleModalTransfer,
            openRoleModalCategory,
            openRoleModalBrand,
            openRoleModalBulk,
            openRoleModalPrices,
            createRoleRef,
            onClickOutsideInviteRef,
            deleteModalRef,
            members,
            pendingInvites,
            changeRoleModalRef,
            emailVerifiedAt,
            showCustomRoleModal,
            createNewRole,
            showBusinessOwnerInfo,
            showSalesTellerInfo,
            // customRoles,
            selectCustomRole,
            editCustomRoles,
            editRole,
            isChecked,
            roleToggle,
            disabledTransaction,
            disableInventory,
            disableDebtor,
            disableEntity,
            disableReport,
            disabledTransactionAccess,
            ALL_PERMISSIONS,
            NOT_PERMITTED,
            role,
            rolePermissions,
            v$,
            rules,
            roleValidation,
            toggleValue1,
            toggleValue2,
            toggleValue3,
            toggleValue4,
            toggleValue5,
            toggleValue6,
            hideBusinessOwnerAccess,
            hideOtherRolesAccess,
            showCreateCustomAccess,
            disabledTransactionDeleteAccess,
            disabledTransactionEditAccess,
            disabledAllTransactionAccess,
            disabledAllSalesTransactionAccess,
            disabledAllExpensesTransactionAccess,
            disabledAllPurchasesTransactionAccess,
            disabledAllMoneyInTransactionAccess,
            disabledAllMoneyOutTransactionAccess,
            othersSelectAll,
            reportSelectAll,
            customerDetailsSelectAll,
            debtorSelectAll,
            creditorSelectAll,
            bulkSelectAll,
            entitySelectAll,
            hideSalesTellerAccess,
            showCreateRole,
            disabledAllInvoiceTransactionAccess,
            inventoryAllServicesAccess,
            inventoryAllProductAccess,
            inventoryAllPurchaseOrderAccess,
            inventoryAllTransferAccess,
            inventoryAllCategoryAccess,
            inventoryAllBrandAccess,
            inventoryAllBulkAccess,
            inventorySelectAll,
        }
    },
}
</script>

<style scoped>
.gap4 {
    gap: 4px;
}
.font11 {
    font-size: 11px;
}
.font12 {
    font-size: 12px;
}
.togglePlan {
    --toggle-width: 25px;
    --toggle-height: 12.5px;
    --toggle-bg-on: #febf12;
    --toggle-bg-off: #fff;
    --toggle-border-on: #febf12;
    --toggle-border-off: #c8b6b6;
    --toggle-ring-width: 3px;
    --toggle-ring-color: transparent;
    --toggle-handle-enabled: #febf12;
    --toggle-handle-disabled: #fff;
    --toggle-border: 1px;
}
input[id='toggle']:checked + .toggle .toggle-handle {
    background-color: #fff;
}
</style>
