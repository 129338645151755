<template>
    <div class="w-100 center mv4">
        <div style="">
            <div class="flex w-100 scrollmenu link-container">
                <router-link active-class="active-header" style="" :to="{ name: 'Teams' }">
                    <p class="pv3 pa4 w-100 b">Team</p>
                </router-link>
                <router-link active-class="active-header" to="/settings/roles">
                    <p class="pv3 pa3 w-100 b">Manage Roles</p>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'TeamsHeader',

    setup() {
        const store = useStore()
        const role = computed(() => store?.state?.Auth?.role)

        return {
            role,
        }
    },
}
</script>

<style scoped>
.active-header {
    background: white;
    color: #132c8c !important;
}
.link-container {
    background: #132c8c;
    color: white;
    width: fit-content;
    border: 2px solid #132c8c;
    border-radius: 20px;
    background-color: #132c8c;
}
.link-container a{
    color: white;
}
</style>
